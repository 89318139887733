import React from 'react';
import '../../styles/pages/products.css';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Components
import Layout from '../layout';
import ProductCard from '../product-card';

const products = {
  en: {
    ac: 'Air Conditioning',
    ec: 'Engine Cooling',
    acProducts: [
      { title: 'Expansion Valve', path: '/en/products/intro/expansion-valve' },
      { title: 'Compressor', path: '/en/products/intro/compressor' },
      { title: 'Filter Dryer', path: '/en/products/intro/filter-dryer' },
      { title: 'Condenser', path: '/en/products/intro/condenser' },
      { title: 'Blow Motor', path: '/en/products/intro/blow-motor' },
      { title: 'Evaporator', path: '/en/products/intro/evaporator' },
    ],
    ecProducts: [
      { title: 'Radiator', path: '/en/products/intro/radiator' },
      { title: 'Cooling Fan', path: '/en/products/intro/cooling-fan' },
    ],
  },
  zh: {
    ac: '空調',
    ec: '引擎冷卻',
    acProducts: [
      { title: '膨脹閥', path: '/products/intro/expansion-valve-zh' },
      { title: '壓縮機', path: '/products/intro/compressor-zh' },
      { title: '乾燥過濾器', path: '/products/intro/filter-dryer-zh' },
      { title: '冷凝器', path: '/products/intro/condenser-zh' },
      { title: '鼓風機馬達', path: '/products/intro/blow-motor-zh' },
      { title: '蒸發器', path: '/products/intro/evaporator-zh' },
    ],
    ecProducts: [
      { title: '散熱器', path: '/products/intro/radiator-zh' },
      { title: '冷卻風扇', path: '/products/intro/cooling-fan-zh' },
    ],
  },
};

const Products = (props) => {
  const {
    pageContext: { langKey },
  } = props;
  return (
    <Layout>
      <Container className="Products" style={{ maxWidth: '780px' }}>
        <Row>
          <Col sm={12} md={6} lg={6}>
            <Row>
              <p style={{ marginLeft: '15px' }}>{products[langKey].ac}</p>
              {products[langKey].acProducts.map((ac) => (
                <Col md={12} style={{ padding: '5px 15px' }} key={ac.title}>
                  <Link to={ac.path}>
                    <ProductCard category={ac.title} />
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <Row>
              <p style={{ marginLeft: '15px' }}>{products[langKey].ec}</p>
              {products[langKey].ecProducts.map((ec) => (
                <Col md={12} style={{ padding: '5px 15px' }} key={ec.title}>
                  <Link to={ec.path}>
                    <ProductCard category={ec.title} />
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Products;
